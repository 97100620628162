import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sg-external-services',
  templateUrl: './external-services.component.html',
  styleUrls: ['./external-services.component.scss']
})
export class ExternalServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export class Permissions {
    static readonly ViewMscSiteList: string = "ViewMscSiteList";
    static readonly ViewMscSiteDetail: string = "ViewMscSiteDetail";
    static readonly EditMscSiteDetail: string = "EditMscSiteDetail";
    static readonly AdvancedEditMscSiteDetail: string = "AdvancedEditMscSiteDetail";
    static readonly ActivateSiteAndPromotions: string = "ActivateSiteAndPromotions";
    static readonly DeActivateSiteAndPromotions: string = "DeActivateSiteAndPromotions";
    static readonly AdvancedTargetEdit: string = "AdvancedTargetEdit";
    static readonly MimicHCPonKnipperMSCSite: string = "MimicHCPonKnipperMSCSite";
    static readonly MimicHCPonProgramMSCSiteBasic: string = "MimicHCPonProgramMSCSiteBasic";
    static readonly MimicHCPonProgramMSCSiteAdvanced: string = "MimicHCPonProgramMSCSiteAdvanced";
    static readonly ViewPrograms: string = "ViewPrograms";
    static readonly SearchPrograms: string = "SearchPrograms";
    static readonly EditUserProfile: string = "EditUserProfile";
    static readonly ViewHcpUserList: string = "ViewHcpUserList";
    static readonly ViewAllHcpOrders: string = "ViewAllHcpOrders";
    static readonly ViewMyHcpOrders: string = "ViewMyHcpOrders";
    static readonly MscReorder: string = "MscReorder";
    static readonly ViewProductCatalog: string = "ViewProductCatalog";
    static readonly ViewShoppingCart: string = "ViewShoppingCart";
    static readonly UpdateShoppingCart: string = "UpdateShoppingCart";
    static readonly EditAddressBook: string = "EditAddressBook";
    static readonly ViewResourceManagement: string = "ViewResourceManagement";
    static readonly ViewGoogleAnalytics: string = "ViewGoogleAnalytics";
    static readonly ViewOrderList: string = "ViewOrderList";
    static readonly ViewOrderDetail: string = "ViewOrderDetail";
    static readonly ViewReportList: string = "ViewReportList";
    static readonly ViewHCPRegistrationReportDetail: string = "ViewHCPRegistrationReportDetail";
    static readonly ViewHCPsNotOrderedReportDetail: string = "ViewHCPsNotOrderedReportDetail";
    static readonly ViewRoleList: string = "ViewRoleList";
    static readonly CreateNewRole: string = "CreateNewRole";
    static readonly ViewRoleDetail: string = "ViewRoleDetail";
    static readonly EditRoleDetail: string = "EditRoleDetail";
    static readonly DeleteRoleDetail: string = "DeleteRoleDetail";
    static readonly CreateNewKnipperAdminUser: string = "CreateNewKnipperAdminUser";
    static readonly ViewKnipperAdminUserList: string = "ViewKnipperAdminUserList";
    static readonly ViewKnipperAdminUserDetail: string = "ViewKnipperAdminUserDetail";
    static readonly EditKnipperAdminUserDetail: string = "EditKnipperAdminUserDetail";
    static readonly CreateNewClientAdminUser: string = "CreateNewClientAdminUser";
    static readonly ViewClientAdminUserDetail: string = "ViewClientAdminUserDetail";
    static readonly EditClientAdminUserDetail: string = "EditClientAdminUserDetail";
    static readonly ViewClientAdminUserList: string = "ViewClientAdminUserList";
    static readonly ViewHcpUserDetail: string = "ViewHcpUserDetail";
    static readonly EditHcpUserDetail: string = "EditHcpUserDetail";
    static readonly AdvanceEditHcpUserDetail: string = "AdvanceEditHcpUserDetail";
}

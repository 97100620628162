import { NgModule, Output } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';

const homeRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(homeRoutes)
    ]
})
export class HomeRoutingModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { SamplicityChartComponent } from '../../../dashboard/components/chart/samplicity-chart.component';
import {activeHcpChartConfig} from '../activeHcpChartConfig';
import {Chart} from 'chart.js'
import { MatExpansionPanel } from '@angular/material';

@Component({
  selector: 'app-active-hcp-chart',
  templateUrl: './active-hcp-chart.component.html',
  styleUrls: ['./active-hcp-chart.component.scss'],
  viewProviders: [MatExpansionPanel]
})
export class ActiveHcpChartComponent implements OnInit {
  @ViewChild('activeHcpChart', { static: true }) activeHcpChart: SamplicityChartComponent;
  activeHcpChartOptions = activeHcpChartConfig.config;
  chart: any;
 

  panelOpenState = true;
  constructor() { }

  ngOnInit() {
    
    this.chart = new Chart('bar', {
      type: 'bar',
      options: {
        responsive: true,
        scaleShowGridLines: false,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true
        },
        animation: {
          duration: 1,
          
          onComplete: function () {
            var ctx = this.chart.ctx;
            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'Open Sans', Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            

            this.data.datasets.forEach(function (dataset) {
                for (var i = 0; i < dataset.data.length; i++) {
                    var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                        scale_max = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
                    ctx.fillStyle = '#00488a';
                    var y_pos = model.y -9;
                    // var y_pos ;
                    
                    if ((scale_max - model.y) / scale_max >= 0.93)
                        y_pos = model.y + 20; 
                    ctx.fillText(dataset.data[i], model.x, y_pos);
                    // ctx.fillText("Hello World", 0, 0);
                    var text = ctx.measureText(dataset.data[i]);
                    ctx.fillRect((model.x-6), y_pos, text.width, 2);
                }
            });               
          }
        },
        legend: {
          display: false,
         
          
          
        },
        scales: {
          xAxes: [{
            display: true,
            stacked: false,
            gridLines : {
              display : false,
              offsetGridLines: true
            },
           
          }
        ],
          yAxes: [{
            display: false,
            stacked: false,
            ticks: {
              beginAtZero: true
            }
          }]
        }
       


      },
      data: {
        labels: ['JKC DTP Veeva','JKC DTP Eyecare'],
        datasets: [
          {
            data: [210,175],
            backgroundColor: ' #a1c1de',
            hoverBackgroundColor: ' #a1c1de',
            fill: false,
          },
        
        ]
      }
    });
   

  
  
  }
 
}

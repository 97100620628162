
import { ChartColors } from '../../../app/dashboard/components/chart/chart-colors';

export const activeHcpChartConfig = {
    config: {
      
      type: 'bar',
      options: {
        responsive: true,
        scaleShowGridLines: false,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true
        },
        title: {
          display: true
        },
        animation: {
          duration: 1,
          onComplete: function () {
              var chartInstance = this.chart,
                  ctx = chartInstance.ctx;
                  ctx.textAlign = 'center';
                  ctx.fillStyle = "rgba(0, 0, 0, 1)";
                  ctx.textBaseline = 'bottom';
          }},
        legend: {
          display: false,
          },
        scales: {
          xAxes: [{
            display: true,
            stacked: false,
            gridLines : {
              display : false,
              offsetGridLines: true
            },
           
          }
        ],
          yAxes: [{
            display: false,
            stacked: false,
            ticks: {
              beginAtZero: true
            }
          }]
        }
      },
      colors: [
        {
          backgroundColor: ' #a1c1de',
         hoverBackgroundColor: ' #a1c1de',
        },
      ],
     
      
      data: {
        labels: ['JKC DTP Veeva','JKC DTP Eyecare'],
        datasets: [
          {
            data: [210,175],
            fill: false,
          },
        
        ]
      }
       
      
     
    },
    
};

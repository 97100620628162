import { NgModule } from '@angular/core';
import { CacheService } from './cache.service';
@NgModule({
  providers: [
    CacheService
  ]
})
export class CacheModule {
  constructor() { }
}
